// Import core modules
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Import libraries
import { useAppContext } from '../../lib/context';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Button,
  Content,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  Switcher,
  SwitcherDivider,
  SwitcherItem
} from 'carbon-components-react';

import { UserAvatar20 } from '@carbon/icons-react';

/**
 * Core function
 */
export default function UMTHeaderLoggedIn({ history, match }) {
  const [userExpanded, setUserExpanded] = useState(false);
  const [fullName, setFullName] = useState('');

  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }

  const toggleUserMenu = () => {
    setUserExpanded(!userExpanded);
  };

  function handleLogout() {
    localStorage.setItem('isAuthenticated', false);
    localStorage.setItem('vAccessToken', '');
    localStorage.setItem('uAccessToken', '');
    localStorage.setItem('accessToken', '');
    window.location.assign("/");
  }

  useEffect(() => {
    var jwt = decodeJWT(localStorage.getItem('accessToken'));

    if (!jwt.roles.includes('000000000000000000000305')) {
      localStorage.setItem('isAuthenticated', false);
      localStorage.setItem('vAccessToken', '');
      localStorage.setItem('uAccessToken', '');
      localStorage.setItem('accessToken', '');
      window.location.assign("/notauthorized");
    }

    var now = new Date().getTime();
    var vExpiry = localStorage.getItem('vTokenExpiry');
    if (now > vExpiry) {
      handleLogout();
    }
    setFullName(jwt.givenName + " " + jwt.familyName);
  }, []);

  return (
    <>
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Madigan EAST">
              <HeaderName prefix="Madigan">
                EAST
              </HeaderName>
              <HeaderGlobalBar>
                <HeaderGlobalAction aria-label="User Avatar" onClick={toggleUserMenu}>
                  <UserAvatar20 />
                </HeaderGlobalAction>
              </HeaderGlobalBar>
              <HeaderPanel aria-label="User navigation" expanded={userExpanded}>
                <Switcher aria-label="Logged in side panel">
                  <SwitcherItem aria-label="Logged in">Logged in as {fullName}</SwitcherItem>
                  <SwitcherDivider />
                  <SwitcherItem aria-label="Logout" onClick={handleLogout}>Logout</SwitcherItem>
                </Switcher>
              </HeaderPanel>
            </Header>
          </>
        )}
      />
    </>
  );
}
