// Import core modules
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import image from "./img/Hero-Image.jpg";

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ComposedModal,
  Content,
  Form,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderPanel,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Switcher,
  SwitcherItem,
  Tabs,
  Tab,
  TextInput,
} from 'carbon-components-react';
import { Help20, UserAvatar20, Email24, Password24, Json } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

/**
 * Core function
 */
export default function Landing({ history }) {
  const [userExpanded, setUserExpanded] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isEmailLogin, setIsEmailLogin] = useState(true);
  const [sentOtp, setSentOtp] = useState(false);
  const [formErrors, setFormErrors] = useState({});


  const goToHelp = () => {
    window.location.href = 'https://docs.madiganid.com/';
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({});

  function handleLogin() {
    if (sentOtp === true) {
      checkOtp();
    } else {
      handleEmailLogin();
    }
  }

  function handleEmailLogin() {
    let errorList = [];
    let formIsValid = true;
    if (email === '' || !email.includes('@')) {
      errorList.email = 'Enter a valid email';
      formIsValid = false;
    }

    if (!formIsValid) {
      console.log('form is invalid!');
      setFormErrors(errorList);
    } else {
      var payLoad = {
        email: email
      };
      axios.post(apiuri + '/otp', payLoad).then((response) => {
        setSentOtp(true);
        setIsEmailLogin(false);
      })
      .catch((error) => {
        console.log('error: ' + JSON.stringify(error, null, 2));
        errorList.email = 'Invalid email';
        setFormErrors(errorList);
      });
    }
  }

  function checkOtp() {
    var errorList = [];
    
    if (otp === '') {
      errorList.otp = 'Enter the one-time passcode'
      setFormErrors(errorList);
    } else {
      var payLoad = {
        code: otp
      };
      
      // Log into umt
      axios.put(apiuri + '/otp/login', payLoad).then((response) => {
        if (response.data.authenticated === true) {
          localStorage.setItem('accessToken', response.data.accessToken);

          // Log into uniqr and verify
          axios.post(apiuri + '/fatlad').then((response) => {
            localStorage.setItem('vAccessToken', response.data.vAccessToken);
            localStorage.setItem('uAccessToken', response.data.uAccessToken);
            localStorage.setItem('vTokenExpiry', response.data.vTokenExpiry);
            localStorage.setItem('isAuthenticated', true);
            window.location.assign('/users');
          }).catch((err) => {
            console.log('Error: ' + err);
          })
          
        } else {
          errorList.otp = 'Code invalid';
          setFormErrors(errorList);
        }
      }).catch((error) => {
        console.log('error: ' + error.message);
        errorList.otp = 'Code is invalid';
        setFormErrors(errorList);
      });
    }
  }

  useEffect(() => {
  }, []);

  return (
    <>
    <div className="landing-bg">
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Madigan EAST">
              <HeaderName prefix="Madigan">
                EAST
              </HeaderName>
            </Header>
          </>
        )}
      />
      <Content className="landing-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-4 landing-container">
              <div className="landing-vertical-center">
                <h3 className="umt--heading">Log in</h3>
                <Form onSubmit={handleSubmit(handleLogin)}>
                    <span style={{ color: 'red' }}>{formErrors['email']}</span>
                    <div style={{ marginBottom: '2rem', 'paddingTop': '20px' }}>
                      <TextInput
                        data-modal-primary-focus
                        value={email}
                        id="email"
                        labelText="Email"
                        onChange={(e) => { setFormErrors([]); setEmail(e.target.value)}}
                      />
                    </div>
                    {
                      sentOtp
                      ?
                      <>
                        <div>
                          An email has been sent to <strong>{email}</strong>
                        </div>
                        <span style={{ color: 'red' }}>{formErrors['otp']}</span>
                        <div style={{ marginBottom: '2rem', 'paddingTop': '25px' }}>
                          <TextInput.PasswordInput
                            data-modal-primary-focus
                            value={otp}
                            id="otp"
                            labelText="One-time passcode"
                            onChange={(e) => { setFormErrors([]); setOtp(e.target.value)}}
                          />
                        </div>
                        <Button
                          className="landing-button"
                          kind="primary"
                          type="submit"
                        >
                          Submit
                        </Button>                
                      </>
                      :
                      <>
                        <Button
                          className="landing-button"
                          kind="primary"
                          type="submit"
                        >
                          Send code
                        </Button>
                      </>
                    }

                </Form>
              </div>
            </div>
            <div className="bx--col-4"></div>            
          </div>
        </div>
      </Content>
    </div>
    </>
  );
}
